/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useStaticQuery, graphql } from 'gatsby';

import pageData from '../CMS/pages/faqs.yml';

import * as styles from '../css/pages/Faqs.module.scss';

import SEO from '../components/layout/SEO';
import Layout from '../components/layout/Layout';
import Question from '../components/pages/faqs/Question';

const FAQs = () => {
    const data = useStaticQuery(graphql`
        query {
            currentBuildDate {
                currentDate
            }
        }
    `);

    const getSchemaData = () => {
        const schema = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            datePublished: '2020-04-30T17:37:00+00:00',
            dateModified: data.currentBuildDate.currentDate,
            mainEntity: [],
        };

        pageData.faqs.forEach(({ question, answer }) => {
            schema.mainEntity.push({
                '@type': 'Question',
                name: question,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: answer,
                },
            });
        });

        return schema;
    };

    const urlParams =
        typeof window !== 'undefined' &&
        window.location.search &&
        new URLSearchParams(window.location.search);
    const question = urlParams && urlParams.get('q');

    // build array of unique sections
    const faqSections = [...new Set(pageData.faqs.map((faq) => faq.section))];

    useEffect(() => {
        if (question) {
            const element = document.getElementById(question);
            if (!element) return; // Don't have that question. Could suggest real one in future?
            const y =
                element.getBoundingClientRect().top + window.pageYOffset + -75;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }, []);

    const renderFaqSection = (section) => {
        const faqs = pageData.faqs.filter((faq) => faq.section === section);
        return (
            <React.Fragment key={section}>
                <h2>{section}</h2>
                {faqs.map((faq, i) => (
                    <Question
                        key={`faq-${i}-${faq.question}`}
                        faq={faq}
                        question={question}
                    />
                ))}
            </React.Fragment>
        );
    };

    return (
        <>
            <SEO
                title="Frequently asked questions"
                description="Our processes can be quite complicated, at Ripples we want to be as simple and transparent as possible. Our FAQ is here to clear up some of the common misconceptions our community finds"
                path="/faqs/"
                schemaData={[getSchemaData()]}
            />
            <Layout mainClass={styles.Faqs}>
                <h1>{pageData.title}</h1>
                <hr />
                <ReactMarkdown children={pageData.intro} />
                <section className={styles.faqsList}>
                    {faqSections.map((section) => renderFaqSection(section))}
                </section>
            </Layout>
        </>
    );
};

export default FAQs;
