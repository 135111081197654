import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

const Question = ({ faq, question }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(faq.question === question);
    }, []);

    return (
        <details
            id={faq.question}
            open={open}
            data-ga-id={`faq__${faq.question.split(' ').join('_')}`}>
            <summary>{faq.question}</summary>
            <ReactMarkdown children={faq.answer} />
        </details>
    );
};

export default Question;
